import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Select, message, Typography, Row, Col, Card, Button } from "antd";
import {
  AssignCourse,
  GetTotalPrice,
  ListofEmployees,
  RemoveCartItem,
} from "../../Constants/Api/Api";
import AuthLayout from "../../Pages/layout/auth";
import SquarePaymentForm from "../SquarePaymentForm/SquarePaymentForm";
import { CloseOutlined } from "@ant-design/icons";
import "./AssignCourseToEmployee.scss";

const { Title } = Typography;

const AssignCourseToEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [finishStatus, setFinishStatus] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);

  const [isAssigning, setIsAssigning] = useState(false); // Loading state for Assign button
  const [isCanceling, setIsCanceling] = useState(false); // Loading state for Cancel button
  const [isBuying, setIsBuying] = useState(false); // Loading state for Buy button

  const { id } = useParams();
  const navigate = useNavigate();
  const reciver = useLocation();

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ListofEmployees(id)
        .then((res) => {
          const formattedData = res.data.data.map((employee) => ({
            label: employee.user_profile.name,
            value: employee.id,
            disabled: employee.is_course_assigned,
          }));
          setEmployees(formattedData);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const fetchTotalPrice = () => {
    if (selectedEmployees.length !== 0) {
    GetTotalPrice().then((res) => {
      console.log(res,"res")
      const data = res?.data?.data;
      setTotalPrice(data.totalPrice);
      setItemCount(data.cartItems.length);
      setCartItems(data.cartItems);
    });
  }
  };

  useEffect(() => {
    fetchTotalPrice();
  }, []);

  const handleAssign = async () => {
   
    try {
      setLoading(true);
      const res = await AssignCourse(selectedEmployees, id);
      if (res.data.code === 200) {
        message.success("Course assigned successfully");
        fetchTotalPrice();
        setShowSummary(true);
      }
    } finally {
      setIsAssigning(false); // Stop loading
    }
  };

  const handleCancel = () => {
    setIsCanceling(true); // Start loading for Cancel button
    setSelectedEmployees([]);
    message.info("Operation cancelled");
    setIsCanceling(false); // Stop loading
  };

  const handleCheckout = () => {
    setIsBuying(true); // Start loading for Buy button
    setIsCheckout(true);
  };

  const handlePaymentCompleted = (data) => {
    message.success("Payment successful!");
    setIsBuying(false); // Stop loading
    setIsCheckout(false);
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };

  const handleCancelPayment = () => {
    setIsCheckout(false);
  };

  return (
    <AuthLayout>
      <div className="assign-course-to-employee">
        <Title level={3}>Assign Course to Employees</Title>
        <h5>{reciver.state}</h5>
        <Row gutter={16}>
          <Col span={showSummary ? 16 : 24}>
            <Card>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select employees..."
                options={employees}
                value={selectedEmployees}
                onChange={setSelectedEmployees}
                maxTagCount="responsive"
                loading={loading}
                size="large"
              />
              <div className="button-group">
                <Button
                  onClick={handleCancel}
                  loading={isCanceling} // Loading spinner for Cancel button
                  className="custom-button"
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={handleAssign}
                  loading={isAssigning} // Loading spinner for Assign button
                  className="custom-button"
                >
                  Assign
                </Button>
              </div>
            </Card>
          </Col>
          {showSummary && (
            <Col span={8}>
              <Card className="summary-card">
                <h2>Summary</h2>
                <div className="summary-details">
                  <h6 className="summary-text">Number of Items:</h6>
                  <h6 className="summary-value">{itemCount}</h6>
                </div>
                {cartItems.map((item) => (
                  <div key={item.id} className="cart-item">
                    <h6>
                      {item.cart_course
                        ? "Item 2: " + item.cart_course.name
                        : "Item 1: " + item.cart_test.name}
                    </h6>
                  </div>
                ))}
                <div className="summary-details">
                  <h6 className="summary-text">Total Amount:</h6>
                  <h6 className="summary-value">${totalPrice}</h6>
                </div>

                <Button
                  type="primary"
                  onClick={handleCheckout}
                  loading={isBuying} // Loading spinner for Buy button
                  className="custom-button buy-button"
                >
                  Buy
                </Button>
              </Card>
            </Col>
          )}
        </Row>
        {isCheckout && (
          <div className="payment-form-container">
            <button className="cancel-button" onClick={handleCancelPayment}>
              <CloseOutlined />
            </button>
            <SquarePaymentForm onPaymentCompleted={handlePaymentCompleted} />
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default AssignCourseToEmployee;
