import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";

import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Table } from "antd";
import moment from "moment";
import AuthLayout from "../layout/auth";
import { GetCourseDataByUserId, GetUserById } from "../../Constants/Api/Api";

const ViewCustomer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [activeTab, setActiveTab] = useState("course");
  const [testMarksData, setTestMarksData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalMarks, setTotalMarks] = useState("");
  const handleModalClose = () => {
    setIsModalVisible(false);
    setTestMarksData([]);
  };

  useLayoutEffect(() => {
    GetUserById(id)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  const getData = async (type) => {
    try {
      let formData = { type };
      let response = await GetCourseDataByUserId(formData, id);
      console.log(response, "response");
      const dataWithIndex = response.data.data.data.map((item, index) => ({
        ...item,
        autoIncrementId: index + 1,
      }));
      setCourseData(dataWithIndex);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData("course");
  }, [id]);

  const navigateToUser = () => {
    navigate("/employeelist");
  };

  const tabsContent = [
    { label: "Course", key: "course" },
    { label: "Test", key: "test" },
  ];

  // const columnsCourse = [
  //   {
  //     title: "ID",
  //     dataIndex: "autoIncrementId",
  //     key: "id",
  //   },
  //   {
  //     title: "Course Name",
  //     dataIndex: ["dashboard_course", "name"],
  //     key: "booking_id",
  //   },
  //   {
  //     title: "Course Price",
  //     dataIndex: ["dashboard_course", "price"],
  //     key: "booking_id",
  //   },
  //   {
  //     title: "Progress",
  //     dataIndex: "progress",
  //     key: "service",
  //   },
  //   {
  //     title: "Certificate Url",
  //     dataIndex: "certificate_url",
  //     key: "type",
  //     render: (certificate_url) => (certificate_url ? certificate_url : "--"),
  //   },
  //   {
  //     title: "Purchase Date",
  //     dataIndex: "created_at",
  //     key: "date",
  //     render: (date) => moment(date).format("DD-MM-YYYY"),
  //   },
  // ];

  // const columnsTest = [
  //   {
  //     title: "ID",
  //     dataIndex: "autoIncrementId",
  //     key: "id",
  //   },
  //   {
  //     title: "Test Name",
  //     dataIndex: ["dashboard_test", "name"],
  //     key: "name",
  //   },
  //   {
  //     title: "Test Duration",
  //     dataIndex: ["dashboard_test", "duration"],
  //     key: "duration",
  //   },
  //   {
  //     title: "Number of Questions",
  //     dataIndex: ["dashboard_test", "question_count"],
  //     key: "question_count",
  //   },
  //   {
  //     title: "Purchase Date",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (date) => moment(date).format("DD-MM-YYYY"),
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (record) =>
  //       record.dashboard_test?.test_marks?.length > 0 ? (
  //         <p
  //           onClick={() => {
  //             setTestMarksData(record.dashboard_test.test_marks);
  //             setTotalMarks(record.dashboard_test.question_count);
  //             setIsModalVisible(true);
  //           }}
  //           style={{
  //             color: "blue",
  //             cursor: "pointer",
  //             fontSize: "large",
  //           }}
  //         >
  //           View Report
  //         </p>
  //       ) : (
  //         "Not Attempted Yet"
  //       ),
  //   },
  // ];
  const columnsCourse = [
    {
      title: "ID",
      dataIndex: "autoIncrementId",
      key: "id",
    },
    {
      title: "Course Name",
      key: "name",
      render: (record) => {
        const courseName =
          record?.dashboard_course?.name ||
          record?.dashboard_course_archive?.name ||
          "---";
        const isDeleted =
          !record.dashboard_course && record.dashboard_course_archive;
        return (
          <span>
            {courseName}
            {isDeleted && <span className="deleted-text"> (*)</span>}
          </span>
        );
      },
    },
    {
      title: "Course Price",
      key: "course_price",
      render: (record) => {
        const coursePrice =
          record.dashboard_course?.price ||
          record.dashboard_course_archive?.price ||
          "---";

        return <span>{coursePrice}</span>;
      },
    },

    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (progress) => (progress !== null && progress !== undefined ? progress : "---"),
    }
,    
    {
      title: "Purchase Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "--"),
    },
    {
      title: "Certificate URL",
      dataIndex: "certificate_url",
      key: "certificate_url",
      render: (certificate_url) =>
        certificate_url ? <a href={certificate_url}>View</a> : "--",
    },
  ];

  const columnsTest = [
    {
      title: "ID",
      dataIndex: "autoIncrementId",
      key: "id",
    },
    {
      title: "Test Name",
      key: "name",
      render: (record) => {
        const courseName =
          record?.dashboard_test?.name ||
          record?.dashboard_test_archive?.name ||
          "---";
        const isDeleted =
          !record.dashboard_test && record.dashboard_test_archive;
        return (
          <span>
            {courseName}
            {isDeleted && <span className="deleted-text"> (*)</span>}
          </span>
        );
      },
    },
    {
      title: "Test Duration",
      key: "duration",
      render: (record) => {
        const testduration =
          record.dashboard_test?.duration ||
          record.dashboard_test_archive?.duration ||
          "---";

        return <span>{testduration}</span>;
      },
    },
    {
      title: "Number of Questions",
      key: "count",
      render: (record) => {
        const courseQuestionCount =
          record?.dashboard_test?.question_count !== undefined
            ? record.dashboard_test.question_count
            : record?.dashboard_test_archive?.question_count !== undefined
            ? record.dashboard_test_archive.question_count
            : "---";
    
        return <span>{courseQuestionCount}</span>;
      },
    },
    
    {
      title: "Purchase Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "--"),
    },
    {
      title: "Certificate URL",
      dataIndex: "certificate_url",
      key: "certificate_url",
      render: (certificate_url) =>
        certificate_url ? <a href={certificate_url}>View</a> : "--",
    },
  ];
  const handleTabSelect = (key) => {
    setActiveTab(key);
    getData(key);
  };

  return (
    <AuthLayout>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
        >
          <div>
            <h3 className="page-title">USER MANAGEMENT</h3>
            <p className="page-sub-title">View Information related with User</p>
          </div>
          <div>
            <Button
              icon="pi pi-arrow-left"
              severity="secondary"
              onClick={navigateToUser}
              style={{ borderRadius: "5px", height: "47px" }}
            >
              <span style={{ marginLeft: "5px" }}>Return to Employee List</span>
            </Button>
          </div>
        </Box>

        <Form className="admin_details_form">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                width: "100%",
                marginRight: "20px",
                marginBottom: "40px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                  marginBottom: "40px",
                }}
              >
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    User's Name:
                  </h5>
                  <p>{userData?.user_profile?.name || "---"}</p>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    Email
                  </h5>
                  <p>{userData?.email || "---"}</p>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    Mobile:
                  </h5>
                  <p>{userData?.user_profile?.mobile || "---"}</p>
                </div>
              </div>
            </Card>
            <Card>
              <div className="admin_profile">
                <Form className="admin_details_form">
                  <div>
                    <h5 style={{ marginBottom: "20px" }}>
                      View all the data associated with{" "}
                      {userData?.user_profile?.name}
                    </h5>
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                      {tabsContent.map((tab) => (
                        <Tab eventKey={tab.key} title={tab.label} key={tab.key}>
                          <div style={{ marginTop: "20px" }}>
                            <Table
                              columns={
                                activeTab === "course"
                                  ? columnsCourse
                                  : columnsTest
                              }
                              dataSource={courseData}
                              rowKey="autoIncrementId"
                            />
                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Form>
        <Modal
          centered
          width={800}
          title="Test Marks Details"
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <Table
            columns={[
              {
                title: "Attempt Number",
                dataIndex: "attempt",
                key: "attempt",
              },
              {
                title: "Marks",
                dataIndex: "test_marks",
                key: "test_marks",
                render: (test_marks) => `${test_marks}/${totalMarks || "--"}`,
              },
              {
                title: "Test Attempt Date",
                dataIndex: "created_at",
                key: "created_at",
                render: (date) => moment(date).format("DD-MM-YYYY"),
              },
            ]}
            dataSource={testMarksData}
            rowKey="autoIncrementId"
            pagination={false}
          />
        </Modal>
      </Box>
    </AuthLayout>
  );
};

export default ViewCustomer;
