import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Divider, message, Button, Spin } from "antd";
import { useNavigate } from "react-router";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import { FaUserAlt } from "react-icons/fa";
import { AiOutlinePhone } from "react-icons/ai";
import SignUpImg from "../../assets/SignupImg.png";
import Logo from "../../assets/Logo.png";
import googleImg from "../../assets/google.png";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { SignUpAPI } from "../../Constants/Api/Api";
import PhoneInput from "react-phone-input-2";
import "./SignUp.scss";
const SignUp = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      name: values.fullname,
      email: values.email,
      mobile: values.phone,
      password: values.password,
      confirm_password: values.confirmPassword,
      company_name: values.companyname,
    };
    SignUpAPI(formData)
      .then((res) => {
       
          message.success(res.data.message);
          setTimeout(() => {
            navigate("/verifyotp", { state: formData.email });
          }, 2000);
        }
      )
      .catch((e) => {
        setLoading(false);
        console.log("error", e);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="signup">
      <Row className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={SignUpImg} alt="signup" className="loginImg" />
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" />
            <h1 className="heading">Sign Up</h1>
            <p className="para">Enter your details to get started</p>
            <div className="form">
              <Spin spinning={loading}>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Full Name"
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your full name!",
                      },
                    ]}
                  >
                    <Input
                      className="input_custom"
                      placeholder="Full Name"
                      prefix={<FaUserAlt size={16} />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Name"
                    name="companyname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your company name!",
                      },
                    ]}
                  >
                    <Input
                      className="input_custom"
                      placeholder="Company Name"
                      prefix={<HiOutlineBuildingOffice2 />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                      { type: "email", message: "Please enter a valid email!" },
                    ]}
                  >
                    <Input
                      className="input_custom"
                      placeholder="Email"
                      prefix={<MdOutlineEmail />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        validator: (_, value) =>
                          value && value.length === 11
                            ? Promise.resolve()
                            : Promise.reject(
                                "Please input a valid US phone number!"
                              ),
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us"]}
                      masks={{ us: "(...) ...-...." }}
                      inputStyle={{ width: "100%" }}
                      containerClass="input_custom"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters!",
                      },
                      {
                        pattern: /^(?=.*[A-Z])(?=.*\d)/,
                        message:
                          "Password must include at least one uppercase letter and one number!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="input_custom"
                      placeholder="Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Passwords do not match!");
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="input_custom"
                      placeholder="Confirm Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    className="button_theme"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form>
              </Spin>
            </div>
            <div className="bottom_text">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/")}
                style={{ cursor: "pointer", color: "#3D3C6E" }}
              >
                Login
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SignUp;
