import React, { useEffect, useState } from "react";
import { Space, Table, message, Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteEmployeeAPI, GetAllEmployeeAPI } from "../../Constants/Api/Api";
import { Button } from "primereact/button";
import AuthLayout from "../layout/auth";
import { SearchOutlined } from "@ant-design/icons";

import "./EmployeeList.scss";

const EmployeeList = () => {
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState(""); // New state for search text
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: null,
    sortOrder: null,
  });

  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const headers = {
    "x-access-token": token,
    role_id: 8,
  };

  const fetchEmployee = async (params = {}) => {
    setLoading(true);
    try {
      const res = await GetAllEmployeeAPI(headers);
      const formattedData = res.data.data.map((employee, index) => ({
        srNo: index + 1,
        id: employee.id,
        name: employee.user_profile.name,
        email: employee.email,
        courses: employee.user_attachments
          .map((attachment) => attachment.course_name)
          .join(", "),
      }));
      setData(formattedData);
      setBackupData(formattedData);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployee({
      page: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      sortField: tableParams.sortField,
      sortOrder: tableParams.sortOrder,
    });
  }, [tableParams]);

  const handleDelete = (userIds) => {
    Modal.confirm({
      title: "Confirm",
      content: `Are you sure you want to delete ${
        userIds.length > 1 ? "these employees" : "this employee"
      }?`,
      onOk: async () => {
        try {
          const response = await DeleteEmployeeAPI(userIds, headers);
          if (response.data.code === 204) {
            message.success("Employee(s) deleted successfully");
            fetchEmployee();
          } 
        } catch (error) {
          console.error("Error deleting employee(s):", error);
        }
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  // Updated search logic
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    const filteredData = backupData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase())
    );
    setData(filteredData);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "srNo",
      key: "srNo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon="pi pi-eye"
            rounded
            outlined
            className="mr-2"
            severity="success"
            style={{
              borderRadius: "25px",
              borderColor: "#689f38",
            }}
            onClick={() => navigate(`/viewemployee/${record.id}`)}
          />
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            severity="danger"
            style={{ borderRadius: "25px", color: "red", borderColor: "red" }}
            onClick={() => handleDelete([record.id])}
          />
        </Space>
      ),
    },
  ];

  const handleNavigate = (navLink) => {
    navigate(navLink);
  };

  return (
    <AuthLayout>
      <div>
        <h3 className="page-title">EMPLOYEE MANAGEMENT</h3>
        <p className="page-sub-title">Employee List View and Delete</p>
      </div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="header">
        
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            className="searchInput"
            value={searchText}
            onChange={handleSearchChange} 
          />
          <Button
            icon="pi pi-trash"
            severity="danger"
            className="deleteBtn"
            onClick={() => handleDelete(selectedRowKeys)}
            disabled={!selectedRowKeys.length}
          />
          <Button
            icon="pi pi-plus"
            severity="info"
            className="addBtn"
            onClick={() => handleNavigate("/addemployee")}
          />
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </AuthLayout>
  );
};

export default EmployeeList;
