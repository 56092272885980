import React, { useEffect, useMemo, useState } from "react";
import { Grid, Card, Button, CircularProgress } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import AuthLayout from "../layout/auth";
import "./Dashboard.scss";
import employeeData from "./employeeData.json";
import {
  CourseCompletedEmployees,
  EmployeesWithCertificates,
  MostPurchasedCourse,
  NumberOfSharedData,
  TotalAmountSpent,
  TotalCoursePurchased,
  TotalEmployeeAdded,
  EmployeeStatistics,
  CourseStatistics,
  EmployeeEngaged,
} from "../../Constants/Api/Api";
import { getLocalStorageItem } from "../../Constants/Api/locastorageHandle";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalEmployees: null,
    totalCourses: null,
    mostPurchasedCourse: null,
    numberOfSharedData: null,
    amountSpent: null,
    courseCompleted: null,
    certifiedEmployee: null,
    engagedEmployees: null,
  });

  const [period, setPeriod] = useState("today");
  const [coursePeriod, setCoursePeriod] = useState("today");
  const [barData, setBarData] = useState([]);
  const [courseBarData, setCourseBarData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const token = getLocalStorageItem("corporateToken");

  const fetchEmployeeStatistics = async (period) => {
    try {
      const response = await EmployeeStatistics(period);
      const data = response.data.data;

      const newBarData = [
        { name: "Today", employees: period === "today" ? data : 0, courses: 0 },
        {
          name: "This Week",
          employees: period === "week" ? data : 0,
          courses: 0,
        },
        {
          name: "This Month",
          employees: period === "month" ? data : 0,
          courses: 0,
        },
      ];

      setBarData(newBarData);
    } catch (error) {
      console.log("Error fetching employee statistics:", error);
    }
  };

  const fetchCourseStatistics = async (coursePeriod) => {
    try {
      const response = await CourseStatistics(coursePeriod);
      const data = response.data.data;

      const newCourseBarData = [
        { name: "Today", courses: coursePeriod === "today" ? data : 0 },
        { name: "This Week", courses: coursePeriod === "week" ? data : 0 },
        { name: "This Month", courses: coursePeriod === "month" ? data : 0 },
      ];

      setCourseBarData(newCourseBarData);
    } catch (error) {
      console.log("Error fetching course statistics:", error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true); // Start loading
      try {
        const [
          employeeResponse,
          courseResponse,
          purchasedResponse,
          sharedDataResponse,
          amountSpentResponse,
          completedCoursesResponse,
          certifiedEmployeeResponse,
          engagedEmployeesResponse,
        ] = await Promise.all([
          TotalEmployeeAdded(),
          TotalCoursePurchased(),
          MostPurchasedCourse(),
          NumberOfSharedData(),
          TotalAmountSpent(),
          CourseCompletedEmployees(),
          EmployeesWithCertificates(),
          EmployeeEngaged(),
        ]);

        setDashboardData({
          totalEmployees: employeeResponse.data.data,
          totalCourses: courseResponse.data.data,
          mostPurchasedCourse: purchasedResponse.data.data.courseName,
          numberOfSharedData: sharedDataResponse.data.data,
          amountSpent: amountSpentResponse.data.data,
          courseCompleted: completedCoursesResponse.data.data,
          certifiedEmployee: certifiedEmployeeResponse.data.data,
          engagedEmployees: engagedEmployeesResponse.data.data,
        });
      } catch (error) {
        console.log("Error fetching dashboard data:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    if (token) {
      fetchAllData();
    }
  }, [token]);

  useEffect(() => {
    fetchEmployeeStatistics(period);
    fetchCourseStatistics(coursePeriod);
  }, [period, coursePeriod]);

  const pieData = [
    { name: "Engaged", value: employeeData.engagedEmployees },
    { name: "Completed", value: employeeData.completedCourses },
    { name: "Certificates", value: employeeData.employeesWithCertificates },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  return (
    <AuthLayout>
      <div className="dashboard-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <h3 className="dashboard_title">Dashboard</h3>
          <p className="page-sub-title" style={{ color: "green" }}>
            Welcome to Web Admin Panel!
          </p>
        </div>

        <Grid container spacing={3}>
          <div className="metrics-row">
            <div className="metric-card">
              <div>
                <p className="desc">Total Employees Added</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.totalEmployees || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Total Courses Purchased</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.totalCourses || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Most Purchased Course</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.mostPurchasedCourse || "N/A"
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Number of People Shared Data</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.numberOfSharedData || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Total Amount Spent</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.amountSpent || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Employees Completed Courses</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.courseCompleted || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Employees Engaged</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.engagedEmployees || 0
                  )}
                </h4>
              </div>
            </div>
            <div className="metric-card">
              <div>
                <p className="desc">Employees with Certificates</p>
                <h4 className="sub-title">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    dashboardData.certifiedEmployee || 0
                  )}
                </h4>
              </div>
            </div>
          </div>

          {/* New Employees Bar Chart */}
          <Grid item xs={12} md={6}>
            <Card className="chart-card">
              <div className="chartHeader">
                <h5 className="chart-title">New Employees</h5>
                <Button
                  className={`button-group-btn ${
                    period === "today" ? "active" : ""
                  }`}
                  onClick={() => setPeriod("today")}
                >
                  Today
                </Button>
                <Button
                  className={`button-group-btn ${
                    period === "week" ? "active" : ""
                  }`}
                  onClick={() => setPeriod("week")}
                >
                  This Week
                </Button>
                <Button
                  className={`button-group-btn ${
                    period === "month" ? "active" : ""
                  }`}
                  onClick={() => setPeriod("month")}
                >
                  This Month
                </Button>
              </div>
              <BarChart width={600} height={350} data={barData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="employees" fill="#8884d8" barSize={40} />
              </BarChart>
            </Card>
          </Grid>

          {/* Courses Purchased Bar Chart */}
          <Grid item xs={12} md={6}>
            <Card className="chart-card">
              <div className="chartHeader">
                <h5 className="chart-title">Courses Purchased</h5>
                <Button
                  className={`button-group-btn ${
                    coursePeriod === "today" ? "active" : ""
                  }`}
                  onClick={() => setCoursePeriod("today")}
                >
                  Today
                </Button>
                <Button
                  className={`button-group-btn ${
                    coursePeriod === "week" ? "active" : ""
                  }`}
                  onClick={() => setCoursePeriod("week")}
                >
                  This Week
                </Button>
                <Button
                  className={`button-group-btn ${
                    coursePeriod === "month" ? "active" : ""
                  }`}
                  onClick={() => setCoursePeriod("month")}
                >
                  This Month
                </Button>
              </div>
              <BarChart width={600} height={350} data={courseBarData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="courses" fill="#82ca9d" barSize={40} />
              </BarChart>
            </Card>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default Dashboard;
