import apiClient from "./apiClient";
// implementation with new Design

// SignUp Api
export const SignUpAPI = async (formData) => {
  return await apiClient.post(`/corporate/register`, formData);
};

//Verify OTP API

export const VerifyOTPAPI = async (formData) => {
  return await apiClient.post(`/corporate/verify-otp`, formData);
};


export const resetPassword = async (formData) => {
  return await apiClient.post(`/corporate/reset-password`, formData);
};

//Get All Employee Api
export const GetAllEmployeeAPI = async () => {
  return await apiClient.get(`/corporate/getAllEmployee`);
};

//Delete Employee Api
export const DeleteEmployeeAPI = async (user_id) => {
  return await apiClient.post(`/corporate/deleteEmployee`, {
    user_id: user_id,
  });
};

//Add Employee
export const CreateEmployeeAPI = async (formData) => {
  return await apiClient.post(`/corporate/addEmployee`, formData);
};

// Get Profile
export const GetProfileAPI = async () => {
  return await apiClient.get(`/corporate/profile`);
};

// Update Profile
export const UpdateProfileAPI = async (formData) => {
  return await apiClient.put(`/corporate/updateManagerProfile`, formData);
};

// Forgot Password apis
export const sendOtpAPi = async (formData) => {
  return await apiClient.post(`/corporate/otp`, formData);
};

export const VerifyAPi = async (formData) => {
  return await apiClient.post(`/corporate/verify-otp`, formData);
};
export const ForgotPasswordApi = async (formData) => {
  return await apiClient.post(`/corporate/forgot-password`, formData);
};

//Get All Share Data
export const GetAllShareData = async () => {
  return await apiClient.get(`/corporate/getListOfEmloyeesSharedData`);
};
//Get All Courses
export const GetAllCourses = async () => {
  return await apiClient.get(`/course/getAllCourse`);
};
//List of Employees
export const ListofEmployees = async (course_id) => {
  return await apiClient.post(`/corporate/assignCourse/listOfEmployees`, {
    course_id: course_id,
  });
};
//Assign Course To Employee
export const AssignCourse = async (user_id, course_id) => {
  const config = {
    user_id: user_id,
    course_id: course_id,
  };
  return await apiClient.post(
    `/corporate/assignCourse/assignCourseToEmployee`,
    config
  );
};

//Get Total Price
export const GetTotalPrice = async () => {
  return await apiClient.get(`/corporate/assignCourse/getTotalPrice`);
};

export const BuyItemsInCart = async () => {
  return await apiClient.post(
    `/corporate/assignCourse/buyItemsInCartForEmployee`,
    null
  );
};

export const RemoveCartItem = async () => {
  return await apiClient.post(
    `/corporate/assignCourse/deleteAllItemsFromCart`,
    null
  );
};

//get userby id
export const GetUserById = async (id) => {
  return await apiClient.get(`/corporate/getEmployeeById/${id}`);
};

export const GetCourseDataByUserId = async (formData, id) => {
  return await apiClient.post(
    `/admin/user/getAllItemsFromDashboardForEmployee/${id}`,
    formData
  );
};

export const GetPurchaseHistory = async (formData) => {
  return await apiClient.post(
    `/corporate/assignCourse/getAllPurchasedItemForEmployee`,
    formData
  );
};

// Get Square Key For Payment Getway
export const SquareKey = async () => {
  return await apiClient.get(
    `https://node.nucleargauges.com:5000/api/v1/payment/square/square-key`
  );
};
export const TotalEmployeeAdded = async () => {
  return await apiClient.get(`/admin/dashboard/totalEmployeesAdded`);
};
export const TotalCoursePurchased = async () => {
  return await apiClient.get(`/admin/dashboard/totalCoursePurchased`);
};
export const MostPurchasedCourse = async () => {
  return await apiClient.get(`/admin/dashboard/mostPurchasedCourse`);
};

export const NumberOfSharedData = async () => {
  return await apiClient.get(`/admin/dashboard/numberOfSharedData`);
};
export const TotalAmountSpent = async () => {
  return await apiClient.get(`/admin/dashboard/totalAmountSpent`);
};
export const CourseCompletedEmployees = async () => {
  return await apiClient.get(`/admin/dashboard/countCompletedEmployees`);
};
export const EmployeeEngaged = async () => {
  return await apiClient.get(`/admin/dashboard/countEngagedEmployees`);
};
export const EmployeesWithCertificates = async () => {
  return await apiClient.get(`/admin/dashboard/countEmployeesWithCertificates`);
};

export const EmployeeStatistics = async (data) => {
  const formData = {
    period: data,
  };
  return await apiClient.post(`/admin/dashboard/employeeStatistics`, formData);
};
export const CourseStatistics = async (data) => {
  const formData = {
    period: data,
  };
  return await apiClient.post(`/admin/dashboard/courseStatistics`, formData);
};
