// apiClient.js
import axios from "axios";
import { message } from "antd";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./locastorageHandle";

const Base_URL = `https://node.nucleargauges.com:5000/api/v1/`;

const apiClient = axios.create({
  baseURL: Base_URL,
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    console.log(config, "config");
    const token = getLocalStorageItem("corporateToken");

    if (token) {
      config.headers["x-access-token"] = token;
    }
    config.headers["role_id"] = 8;

    let timezone = config.headers?.timezone;
    config.headers["timezone"] = timezone;

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getLocalStorageItem("corporateToken");

    if (error.response) {
      const statusCode = error.response.status;
      const statusMsg = error.response.data.message;

      if (statusCode === 404) {
        message.error("Session expired. Please log in again.");
        // removeLocalStorageItem("corporateToken");
        // setTimeout(() => {
        //   window.location.href = "/login";
        // }, 1000);
      } else if (statusCode === 401 && statusMsg === "Please authenticate") {
        if (!token) {
          window.location.href = "/login";
        } else {
          // removeLocalStorageItem("corporateToken");
          // setTimeout(() => {
          //   window.location.href = "/login";
          // }, 1000);
        }
      } else if (statusCode === 400) {
        message.error(statusMsg);
      } else if (statusCode === 500) {
        // removeLocalStorageItem("corporateToken");
        //   setTimeout(() => {
        //     window.location.href = "/login";
        //   }, 1000);
        message.error(
          "An unexpected error occurred. Please check your connection."
        );
      }
    } else {
      message.error(
        "An unexpected error occurred. Please check your connection."
      );
    }
    return Promise.reject(error);
  }
);

// Login API with custom headers
export const LoginAPI = async (formData, headers) => {
  return await apiClient.post(`corporate/login`, formData, { headers });
};

export default apiClient;
