import logo from './logo.svg';
import './App.css';
import WebRoutes from './WebRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div><WebRoutes /></div>
  );
}

export default App;
