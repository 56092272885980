import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GetProfileAPI, UpdateProfileAPI } from "../../Constants/Api/Api";
import { message } from "antd";
import AuthLayout from "../layout/auth";
import { Button } from "primereact/button";
import "./Profile.scss";

const Profile = () => {
  const [idData, setIdData] = useState({});
  const token = localStorage.getItem("corporateToken");
  const headers = {
    "x-access-token": token,
    role_id: 8,
  };

  useEffect(() => {
    GetProfileAPI(headers)
      .then((res) => {
        const userProfile = res.data?.data;
        setIdData(userProfile);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, mobile, company } = event.target.elements;
    const formData = {
      name: name.value,
      mobile: mobile.value,
      company_name: company.value,
    };
    try {
      const response = await UpdateProfileAPI(formData, headers);
      if (response.data.status === 200) {
        message.success("Profile updated successfully");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/employeelist");
  };

  return (
    <AuthLayout>
      <div className="profile-container">
        <div className="profile-header">
          <div className="header">
            <div>
              <h3 className="page-title">Admin Profile</h3>
              <p className="page-sub-title">Edit Profile</p>
            </div>
            <div>
              <Button
                icon="pi pi-arrow-left"
                severity="secondary"
                onClick={navigateToDashboard}
                style={{ borderRadius: "5px", height: "47px" }}
              >
                <span style={{ marginLeft: "5px" }}>
                  Return to Employee List
                </span>
              </Button>
            </div>
          </div>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={idData?.name}
              name="name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="tel"
              defaultValue={idData?.mobile}
              name="mobile"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              defaultValue={idData?.company_name}
              name="company"
              required
            />
          </Form.Group>
          <Button
            type="submit"
            label="Submit"
            icon="pi pi-check"
            severity="primary"
          />
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Profile;
