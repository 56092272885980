import React, { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import AuthLayout from "../../Pages/layout/auth";
import { CreateEmployeeAPI } from "../../Constants/Api/Api";
import { Button } from "primereact/button";
import "./AddEmployee.scss";

const AddEmployee = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const headers = {
    "x-access-token": token,
    role_id: 8,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!name) {
      message.error("Please enter name");
      setDisable(false);
      return;
    }
    if (!email) {
      message.error("Please enter email");
      setDisable(false);
      return;
    }

    const formData = {
      name: name,
      email: email,
    };

    try {
      const response = await CreateEmployeeAPI(formData, headers);
      message.success(response?.data?.message);
      setTimeout(() => {
        navigate("/employeelist");
      }, 1000);

      setDisable(false);
    } catch (error) {
      setDisable(false);
    }
  };

  const navigateToEmployeeList = (key) => {
    navigate(`/${key}`);
  };

  return (
    <AuthLayout>
      <div className="add-employee-container">
        <div className="header">
          <div>
            <h3 className="page-title">Employee Management</h3>
            <p className="page-sub-title">Create New Employee</p>
          </div>
          {/* <div>
            <Button
              variant="secondary"
              onClick={navigateToEmployeeList}
              className="back-button"
            >
              <span>
                <FaArrowLeft size={18} />
              </span>
              <span>Return to Employees</span>
            </Button>
          </div> */}
          <div>
            <Button
              icon="pi pi-arrow-left"
              severity="secondary"
              onClick={() => navigateToEmployeeList("employeelist")}
              style={{ borderRadius: "5px", height: "47px" }}
            >
              <span style={{ marginLeft: "5px" }}>Return to Employee List</span>
            </Button>
          </div>
        </div>
        <Card className="card-container">
          <div className="form-container">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="new_form_control"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="new_form_control"
                />
              </Form.Group>
              {/* <div className="btn">
                <button
                  type="button"
                  onClick={navigateToEmployeeList}
                  className="cancel-btn"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={disable}
                  className="save-btn"
                >
                  Save
                </button>
              </div> */}

              <div  className="btn-space">
                <Button
                  icon="pi pi-check"
                  severity="info"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={disable}
                  className="allbtn"
                  style={{
                    backgroundColor: "#3D3C6E",
                    color: "white",
                  }}
                >
                  {disable ? " Saving...." : " Save"}
                </Button>

                <Button
                  icon="pi pi-times"
                  severity="secondary"
                  onClick={() => navigateToEmployeeList("employeelist")}
                  className="allbtn"
                  // style={{
                  //   marginLeft: "10px",
                  //   marginTop: "10px",
                  //   height: "45px",
                  //   padding: "20px",
                  //   borderRadius: "5px",
                  // }}
                >
                   Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default AddEmployee;
