import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Button, Spin } from "antd";
import { useNavigate } from "react-router";
import loginImage from "../../assets/LoginImg.png";
import Logo from "../../assets/Logo.png";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import "./Login.scss";
import { removeLocalStorageItem } from "../../Constants/Api/locastorageHandle";
import { LoginAPI } from "../../Constants/Api/apiClient";

const Login = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const roleID = "8";
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = (values) => {
    const headers = {
  
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Custom timezone header
    };
    const formData = {
      email: values.email,
      password: values.password,
    };
    setLoading(true);
    LoginAPI(formData,headers)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.message);
          removeLocalStorageItem("role_id");
          localStorage.setItem("role_id", res?.data?.data?.role_id);
          const Token = res?.data?.data?.tokens?.access?.token;
          if (Token) {
            removeLocalStorageItem("corporateToken");
            localStorage.setItem("corporateToken", Token);
            navigate("/dashboard");
          }
        }
      })
      .catch((e) => {
        console.error("Error:", e);

        if (
          e?.response?.data?.status === 400 &&
          e?.response?.data?.message ===
            "User is not verified yet. Please verify your OTP first."
        ) {
          setTimeout(() => {
            navigate("/verifyotp", { state: formData.email });
          }, 2000);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={loginImage} alt="login" className="loginImg" />
              <div className="imageOverlay"></div>
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" className="logo" />
            <h1 className="heading">Login</h1>
            <p className="para">Login with your email address & password</p>
            <div className="form">
              <Spin spinning={loading}>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                    ]}
                  >
                    <Input
                      className="login_input"
                      placeholder="Email"
                      prefix={<MdOutlineEmail />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="login_input"
                      placeholder="Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <p
                    className="resend_otp"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    Forgot Password?
                  </p>
                  <Button
                    htmlType="submit"
                    className="button_theme"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form>
              </Spin>
            </div>
          </div>
          <div className="bottom_text">
            Don’t have an account?{" "}
            <span
              onClick={() => navigate("/signup")}
              style={{ cursor: "pointer", color: "#3D3C6E" }}
            >
              Sign Up
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
